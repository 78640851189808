<template>
  <div class="wrap_lemontree">
    <div class="head_lemontree">
      <h1 class="tit_term">{{ termTitle }}</h1>
    </div>
    <div v-if="isTab" class="tab_comm">
      <ul>
        <li
          v-for="tabItem in tabDataList"
          :key="`tab${tabItem.id}`"
          :class="{ active : tabItem.id === tabSelectId }"
          :style="{ width: `${100/tabDataList.length}%` }">
          <button
            class="btn_tab"
            @click="setTabData(tabItem)">{{ tabItem.text }}</button>
        </li>
      </ul>
    </div>
    <div class="body_lemontree" v-html="model[termId]"></div>
    <!-- <div class="body_lemontree">
    </div> -->
  </div>
</template>
<script>
import TermModel from '@/views/terms/model/TermModel';
// mixin
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';

export default {
  name: 'TermLayout',
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  props:{
    tabDataList: Array
  },
  beforeMount(){
    this.termTitle = this.$route.meta.pageTitle;

    if(this.isTab){
      this.setTabData(this.tabDataList[0])
    }else{
      this.termId = this.$route.name;
    }
  },
  computed:{
    isTab(){
      return this.tabDataList && this.tabDataList.length > 0
    }
  },
  data(){
    return{
      model : new TermModel(),
      tabSelectId:'',
      termTitle: '',
      termId: '',
    }
  },
  methods:{
    setTabData(tabItem){
      const { id, termId } = tabItem;
      this.tabSelectId = id;
      this.termId = termId;
    },
    onClickBackKey(){
      this.sendInterface('webEnd');
    }
  },
}
</script>

<style scoped>
.wrap_lemontree{max-width:690px;margin:0 auto;padding:0 20px;box-sizing:border-box}
.head_lemontree{padding:48px 0 24px;border-bottom:1px solid #E7E5E5;}
.head_lemontree .tit_term{font-weight:700;font-size:28px;line-height:40px;color:#111;word-wrap:break-word;word-break:keep-all}

.tab_comm{margin-top:24px;}
.tab_comm ul{overflow:hidden}
.tab_comm li{float:left}
.tab_comm .btn_tab{display:block;width:100%;padding:8px 0;border:1px solid #aaa;border-left:0;font-weight:700;font-size:16px;color:#999}
.tab_comm li:first-child .btn_tab{border-radius:4px 0 0 4px;border-left: 1px solid #aaa}
.tab_comm li:last-child .btn_tab{border-radius:0 4px 4px 0}
.tab_comm li.active .btn_tab{background-color:#F0EAFA;color:#8F69DD}

.body_lemontree{padding:24px 0 40px;font-weight:500;font-size:16px;line-height:24px;color:#666;word-break:break-all}
.body_lemontree ::v-deep h1,
.body_lemontree ::v-deep h2,
.body_lemontree ::v-deep .tit_comm,
.body_lemontree ::v-deep strong{font-weight:600}
.body_lemontree ::v-deep h1{font-weight:600;font-size:20px;line-height:34px;color:#111}
.body_lemontree ::v-deep h2{font-weight:600;font-size:20px;line-height:34px;color:#111}
.body_lemontree ::v-deep h3{font-weight:600;font-size:16px;line-height:30px;color:#333}
.body_lemontree ::v-deep .tit_comm{font-size:20px}    
.body_lemontree ::v-deep em{font-style:italic}
.body_lemontree ::v-deep a{color:#3b8aff;text-decoration:underline}
.body_lemontree ::v-deep img{display:block;width:calc(100% + 40px);margin:0 -20px}
.body_lemontree ::v-deep ul{padding-left:22px}
.body_lemontree ::v-deep ul,
.body_lemontree ::v-deep ul > li{list-style:disc}
/* .body_lemontree ::v-deep ul{padding-left:20px;text-indent:-4px} */
.body_lemontree ::v-deep ul > li > ul,
.body_lemontree ::v-deep ul > li > ul > li{list-style:circle}
.body_lemontree ::v-deep ul > li > ul > li > ul,
.body_lemontree ::v-deep ul > li > ul > li > ul > li{list-style:square}
.body_lemontree ::v-deep > ol > li {
  list-style-type: none;
  counter-increment: my-counter;
}

.body_lemontree ::v-deep > ol > li::before {
  display:inline-block;
  content: counter(my-counter, decimal);
  width:14px;
  height:14px;
  margin:3px 5px 0 -21px;
  line-height:13px;
  font-size:10px;
  text-indent: -0.1em;
  letter-spacing: -0.1em;
  font-weight: 700;
  border:1px solid #666;
  border-radius: 100%;
  text-align:center;
  vertical-align: top;
}
.body_lemontree ::v-deep > ol > li > p{
  display:inline-block;
  vertical-align: top;
}
.body_lemontree ::v-deep > ol > li > ol li::before{
  display:none
}
.body_lemontree ::v-deep ol{padding-left:22px}
.body_lemontree ::v-deep ol > li > ol,
.body_lemontree ::v-deep ol > li > ol > li{list-style:decimal}
.body_lemontree ::v-deep ol > li > ol > li > ol,
.body_lemontree ::v-deep ol > li > ol > li > ol > li{list-style:hangul}
.body_lemontree ::v-deep ol > li > ol{padding-left:20px}
.body_lemontree ::v-deep li+li{padding-top:8px}
.body_lemontree ::v-deep p{min-height:24px}
.body_lemontree ::v-deep table{width:100%;margin:10px 0;border-collapse:collapse;border-spacing:0;table-layout:fixed}
.body_lemontree ::v-deep table th,
.body_lemontree ::v-deep table td{padding:8px 8px;border:1px solid #E7E5E5}
.body_lemontree ::v-deep table thead th{background-color:#E7E5E5}
.body_lemontree ::v-deep table th:first-child,
.body_lemontree ::v-deep table td:first-child{border-left:0}
.body_lemontree ::v-deep table th:last-child,
.body_lemontree ::v-deep table td:last-child{border-right:0}
</style>